// Variables
@import "App.scss";

// Main
body {
  background-color: $primaryColour;
  height: 100%;
  transition: 400ms ease-out;
  main {
    position: relative;
    display: flex;
    flex-direction: row;
    max-width: 1300px;
    width: fit-content;
    margin: 0 auto;
    background-color: $sideBarColour;
  }
}

// Header
header {
  height: 100vh;
  background-color: $sideBarColour;
  svg {
    margin: 0 0 0 auto;
    padding: 30px 0;
    width: 110px;
    color: white;
    background: $sideBarFirstDivColour;
  }
  nav {
    display: none;
    min-width: 200px;
    color: white;
    ul {
      a {
        text-decoration: none;
        li {
          margin: 10px 0;
          list-style: none;
          color: white;
          text-decoration: none;
        }
        &:hover {
          text-decoration: underline;
          font-weight: 600;
          color: white;
        }
      }
    }
  }
}

#nav-icon__close {
  background: $sideBarColour;
  margin: 0 0 0 auto;
  width: fit-content;
  width: 50px;
}

.open {
  display: block;
}

.close {
  display: none;
}

// SideBar.jsx
.side-bar {
  position: fixed;
  width: 290px;
  height: 100%;
  padding: 0 15px;
  float: left;
  background-color: $sideBarColour;
  z-index: 10;
  .side-bar__container {
    padding: 0 15px;
    position: relative;
    .side-bar__container__firstDiv {
      text-align: center;
      background: $sideBarFirstDivColour;
      padding: 0 0 1px 0;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      img {
        width: 90px;
        height: 90px;
        object-fit: cover;
        border-radius: 50%;
        margin: 30px 0 0 0;
      }
      div {
        font-size: 1rem;
        color: $spanColour;
        margin: 0 0 30px 0;
      }
    }
    .side-bar__container__scroll {
      padding: 240px 0 50px 0;
      overflow-y: auto;
      height: calc(100vh - 299px);
      .side-bar__container__secondDiv {
        padding: 30px 3px 15px 0;
        border-bottom: 1px solid $spanColour;

        div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 0 5px 0;
        }
        h6 {
          font-size: 1rem;
          margin: auto 0;
        }
        span {
          font-size: 1rem;
        }
      }
      .side-bar__container__thirdDiv {
        padding: 15px 3px 15px 0;
        border-bottom: 1px solid $spanColour;
        text-align: center;
        div div {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 3px solid $highLight;
          border-radius: 50%;
          width: 53px;
          height: 53px;
          margin: 10px auto 10px auto;
          h6 {
            margin: auto;
          }
        }
        span {
          font-size: 1rem;
        }
      }
      .side-bar__container__fourthDiv {
        padding: 15px 3px 15px 0;
        border-bottom: 1px solid $spanColour;
        text-align: left;
        font-size: 1rem;
        color: white;
        div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px 0 5px 0;
          span {
            font-size: 1rem;
          }
        }
      }
      .side-bar__container__fifthDiv {
        padding: 15px 3px 30px 0;
        text-align: left;
        font-size: 0.875rem;
        color: white;
        div {
          a {
            text-decoration: none;
            color: $spanColour;
            text-transform: uppercase;
          }
        }
      }
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: $spanColour;
        border-radius: 25px;
      }

      &::-webkit-scrollbar-track {
        background-color: $primaryColour;
      }
    }
    .side-bar__container__sixthDiv {
      padding: 15px 3px 15px 0;
      text-align: center;
      font-size: 0.875rem;
      color: $spanColour;
      background: $sideBarFirstDivColour;
      max-height: 50px;
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      .sideBarIcons {
        width: 15px;
        height: 15px;
        margin: 0 5px;
        color: $spanColour;
      }
    }
  }
}

// Body
.body {
  max-width: 800px;
  margin: 0 0 0 290px;
  .header {
    position: relative;
    .body__header {
      position: relative;
      background-image: url(https://images.pexels.com/photos/6564830/pexels-photo-6564830.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2);
      background-size: cover;
      background-position: center;
      opacity: 0.7;
      width: 100%;
      height: 335px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 0;
    }
    .body__header__text {
      position: absolute;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: auto;
      text-align: center;
      color: $highLight;
      padding: 30px;
      right: 0;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
      h1 {
        font-family: Poppins, sans-serif;
        font-size: 1.75rem;
        color: #fff;
        text-shadow: 1px 2px 5px #000;
        .Typewriter {
          span {
            font-family: Poppins, sans-serif;
            font-size: 1.75rem;
            color: #fff;
            text-shadow: 1px 2px 5px #000;
          }
        }
      }
      button {
        background-color: $highLight;
        border: none;
        height: 45px;
        font-size: 1rem;
        padding: 0 35px;
        margin: 10px auto;
        text-transform: uppercase;
        color: $sideBarColour;
        font-weight: 600;
        transition: 450ms ease-out;
        width: fit-content;
        &:hover {
          background-color: $sideBarColour;
          color: $highLight;
        }
      }
    }
  }
  .body__experience {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 15px 0 0 30px;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      label {
        color: $highLight;
        font-size: 1.375rem;
        font-weight: 600;
        margin: 0 10px 0 0;
        span {
          color: $highLight;
          font-size: 1.375rem;
          font-weight: 600;
          margin: 0;
        }
      }
      span {
        font-size: 1rem;
        font-weight: 400;
        color: #fff;
      }
    }
  }
  .body__skills {
    display: flex;
    flex-direction: column;
    padding: 10px 0 0 30px;
    // width: 96%;
    label {
      color: #ffffff;
      font-size: 1.125rem;
    }
    div {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 20px;
      margin: 10px 0;
      div {
        height: auto;
        display: flex;
        flex-direction: column;
        padding: 15px;
        box-shadow: 0 3px 8px 0 rgb(15 15 20 / 20%);
        background: $boxColour;
        label {
          font-size: 1.125rem;
          white-space: nowrap;
        }
        p {
          font-size: 1rem;
        }
      }
    }
  }
  .experience {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
    padding: 10px 0 0 30px;
    // width: 96%;
    label {
      color: #ffffff;
      font-size: 1.125rem;
    }
    div {
      div {
        background: $boxColour;
        position: relative;
        font-size: 1rem;
        color: white;
        margin: 20px 0;
        padding: 15px;
        span {
          background-color: $sideBarColour;
          position: absolute;
          border-radius: 15px;
          padding: 5px 15px;
          right: 0;
          color: white;
          margin: 0 10px 0 50px;
        }
        div {
          font-size: 0.875rem;
          white-space: nowrap;
          color: white;
        }
        p {
          color: white;
          a {
            text-decoration: none;
            color: $highLight;
            font-weight: 600;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        ul {
          font-size: 0.813rem;
          p {
            color: $spanColour;
            font-size: 1rem;
          }
        }
      }
    }
  }
  .body_projects {
    padding: 10px 0 0 30px;
    // width: 96%;
    label {
      color: #ffffff;
      font-size: 1.125rem;
    }
    .body_projects__array {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      margin: 20px 0 0 0;
      a {
        max-height: 250px;
        text-decoration: none;
        .project {
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          display: flex;
          width: 100%;
          height: 100vh;
          max-height: 200px;
          opacity: 0.9;
          &:hover {
            opacity: 1;
          }
          &:hover .project__tag {
            display: inline-block;
          }
          .project__tag {
            display: none;
            margin-top: auto;
            padding: 10px;
            opacity: 0.9;
            background: $boxColour;
            height: 100%;
            width: 100%;
            transition: 450ms ease-out;
            p {
              color: white !important;
            }
          }
        }
      }
    }
  }
  .form {
    padding: 20px 0 0 30px;
    label {
      color: #ffffff;
      font-size: 1.125rem;
    }
    form {
      background: $boxColour;
      padding: 12px;
      margin: 20px 0 0 0;
      input[type="text"],
      input[type="email"] {
        max-width: 740px;
        width: 100%;
        height: 50px;
        background: $sideBarFirstDivColour;
        font-size: 1rem;
        border: none;
        margin: 10px 0;
        color: $spanColour;
        border-style: none;
        padding: 0 0 0 5px;
        transition: 400ms ease-out;
        &:focus {
          outline-color: $highLight;
        }
      }
      input[type="textarea"] {
        background: linear-gradient(
          159deg,
          rgba(37, 37, 50, 0.98),
          rgba(35, 35, 45, 0.98)
        );
        border: none;
        color: #9c9c9c;
        font-size: 1rem;
        max-height: 150px;
        margin: 10px 0;
        max-width: 740px;
        padding: 15px 0 150px 5px;
        transition: 400ms ease-out;
        width: 100%;
        &:focus {
          outline-color: $highLight;
        }
      }
      button {
        background-color: $highLight;
        border: none;
        height: 45px;
        font-size: 1rem;
        padding: 0 35px;
        margin: 10px 0;
        text-transform: uppercase;
        color: $sideBarColour;
        font-weight: 600;
        transition: 400ms ease-out;
        &:hover {
          background-color: $sideBarColour;
          color: $highLight;
        }
      }
    }
  }
  footer {
    padding: 10px 0 0 30px;
    margin: 20px 0 0 0;
    background: $boxColour;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    div {
      padding: 15px;
      color: white;
      font-size: 1rem;
      a {
        color: white;
      }
    }
  }
}
