@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

:root {
  --primaryFontcolour: #fafafc;
  --secondaryFontColour: rgb(156, 156, 156);
}

html {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  color: var(--primaryFontcolour);
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

h5 {
  font-size: 1.125rem;
  font-weight: 600;
}

h6 {
  font-size: 1rem;
  font-weight: 400;
}

p {
  color: var(--secondaryFontColour);
  font-size: 1rem;
}

span {
  color: var(--secondaryFontColour);
}

// Variables
$primaryColour: #191923;
$sideBarColour: #20202a;
$sideBarFirstDivColour: linear-gradient(
  159deg,
  rgba(37, 37, 50, 0.98) 0,
  rgba(35, 35, 45, 0.98) 100%
);
$spanColour: rgb(156, 156, 156);
$highLight: #ffc107;
$boxColour: linear-gradient(159deg, #2d2d3a 0, #2b2b35 100%);

$mobile-large: 1265px;
$mobile-medium: 1264px;
$mobile-small: 750px;

// Mixin
@mixin displayFlex() {
  display: flex;
}

@mixin media($args) {
  @if $args <= 1264 {
    @media (max-width: $args) {
      @content;
    }
  } @else {
    @media (min-width: $args) {
      @content;
    }
  }
}
