// Variables
@import "App.scss";

// Screen Width 1000px
@include media($mobile-medium) {
  // Body
  body {
    transform: 350ms ease-out;
    main {
      flex-direction: column-reverse;
      .body {
        margin: 0 auto;
        .body .body__header {
          padding: 0 15px;
        }
        .body__experience {
          padding: 10px 15px 0 15px;
          div {
            flex-direction: ro;
          }
        }
        .body__skills {
          padding: 10px 15px 0 15px;
        }
        .experience {
          padding: 10px 15px 0 15px;
          grid-template-columns: 1fr;
        }
        .body_projects {
          padding: 10px 15px 0 15px;
        }
        .form {
          padding: 10px 15px 0 15px;
        }
        footer {
          padding: 10px 15px 0 15px;
        }
      }
      .side-bar {
        display: none;
      }
    }
  }

  // Header
  header {
    position: relative;
    max-width: 1200px;
    width: 100%;
    max-width: 800px;
    height: fit-content;
    nav {
      ul {
        padding: 0 15px;
        text-align: right;
      }
    }
  }

  #nav-icon__close {
    background: #20202a;
    margin: 0 0 0 auto;
    width: 50px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    position: relative;
  }
}

@include media($mobile-small) {
  .body {
    .body__experience {
      div {
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
    .body__skills {
      div {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 0;
        margin: 10px 0;
      }
    }
    .body_projects {
      .body_projects__array {
        grid-template-columns: 1fr;
        margin: 20px 0;
      }
    }
    footer {
      padding: 10px 15px 0 15px;
      flex-direction: column-reverse;
      align-items: center;
      div:nth-child(2) {
        padding: 15px 0 0 0;
      }
    }
  }
}

@include media(530px) {
  body main .body .body__experience {
    display: none;
  }
  body main .body .body__skills {
    padding: 20px 15px 0 15px;
  }
}
